import React from "react";
import LineSetting from "../chatSettings/LineSetting";
import AirbnbSetting from "../chatSettings/AirbnbSetting";

const BoundSetting = ({ room, token, clientId, tabs }) => {
  return (
    <>
      {tabs === 0 ? (
        <div className="bg-bgLight h-full min-w-72 max-w-72">
          <AirbnbSetting
            room={room}
            token={token}
            showWebhook={false}
            clientId={clientId}
          />
        </div>
      ) : (
        <div className="bg-bgLight h-full min-w-72 max-w-72 overflow-y-auto">
          <LineSetting room={room} token={token} />
        </div>
      )}
    </>
  );
};

export default BoundSetting;
